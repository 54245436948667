<template>
  <a-drawer
    class="lm-task-modal"
    :getContainer="false"
    width="64.6vw"
    v-model:visible="visible"
    @cancel="closeModal"
    placement="right"
  >
    <template #title>
      <h1 class="drawer-title">{{ title }}</h1>
    </template>
    <div class="modal-body">
      <div>
        <h1>任务详细信息</h1>
        <a-form
          v-if="taskStatus !== 'Done'"
          :model="formState"
          :rules="formRules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="formRef"
        >
          <a-form-item label="任务名称" name="taskName">
            <a-input v-model:value="formState.taskName" placeholder="请输入" />
          </a-form-item>
          <a-form-item
            :label="
              formState.taskStatus && formState.taskStatus !== '0'
                ? '任务成员'
                : '添加成员'
            "
            name="userId"
          >
            <div v-if="formState.taskStatus && formState.taskStatus !== '0'">
              {{ formState.userName }}
            </div>
            <a-select
              v-else
              v-model:value="formState.userId"
              placeholder="请选择"
              allowClear
              show-search
              :filterOption="filterOption"
            >
              <a-select-option
                v-for="item in taskUserOps"
                :value="item.id"
                :key="item.id"
              >
                {{ item.fullName + " " + item.status }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="任务时间" name="taskTime">
            <a-range-picker
              v-model:value="formState.taskTime"
              :placeholder="['开始时间', '结束时间']"
              :allowClear="false"
            />
          </a-form-item>
          <a-form-item label="任务描述" name="taskDescribe">
            <rich-editor
              v-if="visible"
              v-model:modelValue="formState.taskDescribe"
            />
          </a-form-item>
        </a-form>
        <div v-else>
          <div>
            任务时间： {{ formState.beginTime + " - " + formState.endTime }}
          </div>
          <div>任务成员： {{ formState.userName }}</div>
          <div
            v-html="formState.taskDescribe"
            class="content"
            @click="contentClick"
          ></div>
        </div>
        <a-upload
          v-if="taskStatus !== 'Done'"
          v-model:file-list="fileList"
          :remove="removeFile"
          :before-upload="beforeUpload"
          accept=".doc, .docx, .ppt, .pptx, .xls, .xlsx, .pdf, .txt, .zip ,.rar, .7z"
          :customRequest="() => {}"
          multiple
        >
          <a-button type="primary" v-auth="['system:lmBenchTaskDetail:update']">
            <upload-outlined></upload-outlined>
            上传文件
          </a-button>
        </a-upload>
        <a-table
          v-show="tableData.length > 0"
          class="file-table"
          rowKey="id"
          :columns="columns"
          :data-source="tableData"
          :pagination="false"
        >
          <template #action="{ record }">
              <span class="operationLink underline" @click="downloadClick(record)" v-auth="['system:lmBenchTaskDetail:download']">
                下载
              </span>
              <span
                class="redLink underline"
                @click="delClick(record)"
                v-if="taskStatus !== 'Done'"
                v-auth="['system:lmBenchTaskDetail:delete']"
              >
                删除
            </span>
          </template>
        </a-table>
      </div>
      <div v-show="taskStatus !== 'Task'">
        <h1>回答信息</h1>
        <div
          v-html="formState.taskAnswer"
          class="content"
          @click="contentClick"
        ></div>
        <a-table
          v-show="answerTableData.length > 0"
          rowKey="id"
          class="file-table"
          :columns="columns"
          :data-source="answerTableData"
          :pagination="false"
        >
          <template #action="{ record }">
              <span class="operationLink underline" @click="downloadClick(record)" v-auth="['system:lmBenchTaskDetail:download']">
                下载
              </span>
          </template>
        </a-table>
      </div>
      <div v-show="taskStatus !== 'Task'">
        <h1>评论</h1>
        <comment-list
          :comment-list="commentList"
          :task-id="chooseTaskId"
          :photo-url="photoUrl"
          :is-edit="taskStatus !== 'Done'"
          @set-comment-list="setCommentList"
        />
      </div>
    </div>
    <div class="footerBtn">
      <a-space>
        <a-button @click="closeModal" v-auth="['system:lmBenchTaskDetail:close']">取消</a-button>
        <a-button type="primary" v-if="taskStatus !== 'Done'" @click="saveClick" v-auth="['system:lmBenchTaskDetail:close']"
          >保存
        </a-button>
        <a-button
          type="primary"
          v-if="
            taskStatus == 'Task' &&
            (!formState.taskStatus || formState.taskStatus == '0')
          "
          @click="publishClick"
          v-auth="['system:lmBenchTaskDetail:publish']"
          >发布
        </a-button>
      </a-space>
    </div>
  </a-drawer>
  <img-modal
    :visible="imgVisible"
    :imag-url="imgUrl"
    @close-modal="closeImgModal"
  />
</template>

<script>
import { ref, defineComponent, onUnmounted } from "vue";
import RichEditor from "@/components/richEditor";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import moment from "moment";
import { downloadFile } from "@/utils/common";
import { UploadOutlined } from "@ant-design/icons-vue";
import ImgModal from "@/views/home/TaskManagement/BenchTask/component/imgModal";
import CommentList from "@/views/home/TaskManagement/BenchTask/component/commentList";

export default defineComponent({
  components: {
    CommentList,
    ImgModal,
    RichEditor,
    UploadOutlined,
  },
  emits: ["refresh-data"],
  props: {
    taskStatus: String,
  },
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();

    const visible = ref(false);
    const title = ref("");

    const taskUserOps = ref();
    const getTaskUserOps = () => {
      $api
        .queryEmpByLm({ lmIdNumber: store.state.app.user.idNumber })
        .then((res) => {
          taskUserOps.value = res.data;
        });
    };

    const formRef = ref();
    const formState = ref({});
    const formRules = {
      taskName: [
        {
          required: true,
          message: "请输入任务名称",
          trigger: "blur",
        },
      ],
      taskDescribe: [
        {
          required: true,
          message: "请输入任务描述",
          trigger: "blur",
        },
      ],
    };

    const fileList = ref([]);
    const columns = [
      {
        title: "文件名",
        dataIndex: "fileName",
        key: "fileName",
        ellipsis: true,
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "action",
        slots: { customRender: "action" },
        width: 200,
        fixed: "right",
      },
    ];
    const tableData = ref([]);
    const answerTableData = ref([]);

    const commentList = ref();
    const chooseTaskId = ref("");
    const photoUrl = ref("");

    const getLmFileList = (taskId) => {
      $api.getTaskLmFileList({ taskId }).then((res) => {
        tableData.value = res.data;
      });
    };
    const removeFile = (file) => {
      const index = fileList.value.findIndex((f) => f.uid === file.uid);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      fileList.value = newFileList;
    };
    let messageTimer = null;
    const beforeUpload = (file) => {
      return new Promise(() => {
        if (fileList.value.length + 1 + tableData.value.length > 3) {
          if (messageTimer) {
            clearTimeout(messageTimer);
          }
          messageTimer = setTimeout(() => {
            message.error("任务文件最多可以上传3个!");
          });
          return;
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
          message.error("文件最大10MB!");
          return;
        }
        let fileType = file.name.substring(
          file.name.lastIndexOf(".") + 1,
          file.name.length
        );
        if (
          ![
            "doc",
            "docx",
            "ppt",
            "pptx",
            "xls",
            "xlsx",
            "pdf",
            "txt",
            "zip",
            "rar",
            "7z",
          ].includes(fileType)
        ) {
          message.error("文件格式错误");
          return;
        }
        fileList.value = [...fileList.value, file];
      });
    };
    const uploadFile = (taskId) => {
      const formData = new FormData();
      fileList.value.forEach((file) => {
        formData.append("file", file);
      });
      return $api.uploadTaskFile(formData, taskId);
    };
    const downloadClick = (record) => {
      $api.downloadTaskFile(record.id).then((res) => {
        downloadFile(res.file, record.fileName);
      });
    };
    const delClick = (record) => {
      $api.delTaskFile(record.id).then((res) => {
        getLmFileList(formState.value.id);
        message.success(res.msg);
      });
    };

    const closeModal = () => {
      if (formRef.value) {
        formRef.value.resetFields();
      }
      formState.value = {};
      fileList.value = [];
      tableData.value = [];
      answerTableData.value = [];
      visible.value = false;
    };

    const successFunc = (msg) => {
      closeModal();
      ctx.emit("refresh-data");
      message.success(msg);
    };

    const saveClick = () => {
      formRef.value.validate().then(async () => {
        if (formState.value.taskTime && formState.value.taskTime.length > 0) {
          formState.value.beginTime =
            formState.value.taskTime[0].format("YYYY-MM-DD");
          formState.value.endTime =
            formState.value.taskTime[1].format("YYYY-MM-DD");
        }
        if (formState.value.userId) {
          const emp = taskUserOps.value.find(
            (item) => item.id === formState.value.userId
          );
          formState.value.userName = emp ? emp.fullName : undefined;
        }

        const taskRes = await $api.saveTask(formState.value);
        if (fileList.value.length > 0) {
          const params = {
            taskId: taskRes.data.id,
            fileRole: "LM",
          };
          const res = await uploadFile(params);
          successFunc(res.msg);
        } else {
          successFunc(taskRes.msg);
        }
      });
    };

    const publishClick = () => {
      formRef.value.validate().then(async () => {
        if (
          formState.value.taskTime &&
          formState.value.taskTime.length > 0 &&
          formState.value.userId
        ) {
          formState.value.beginTime =
            formState.value.taskTime[0].format("YYYY-MM-DD");
          formState.value.endTime =
            formState.value.taskTime[1].format("YYYY-MM-DD");
          const emp = taskUserOps.value.find(
            (item) => item.id === formState.value.userId
          );
          formState.value.userName = emp ? emp.fullName : undefined;

          const taskRes = await $api.saveTask(formState.value);
          if (fileList.value.length > 0) {
            const params = {
              taskId: taskRes.data.id,
              fileRole: "LM",
            };
            await uploadFile(params);
          }
          const res = await $api.publishBenchTaskList({
            taskId: taskRes.data.id,
          });
          successFunc(res.msg);
        } else {
          message.warning(
            "请将任务信息填写完全(可能是时间或人员信息未填写完全)"
          );
        }
      });
    };

    const createTask = () => {
      visible.value = true;
      formState.value = {
        taskTime: [],
        taskDescribe: "",
        taskName: "",
        userId: undefined,
      };
      fileList.value = [];
      tableData.value = [];
      answerTableData.value = [];
      title.value = "新建任务";
    };
    const editTask = (taskId) => {
      $api.queryTask({ taskId }).then((res) => {
        formState.value = res.data.staffTask;
        if (formState.value.beginTime && formState.value.endTime) {
          formState.value.taskTime = [
            moment(formState.value.beginTime),
            moment(formState.value.endTime),
          ];
        }
        tableData.value = res.data.staffTaskFileLMList;
        answerTableData.value = res.data.staffTaskFileConsultantList;
        commentList.value = res.data.staffTaskCommentList;
        chooseTaskId.value = taskId;
        if (res.data.staffPhoto && res.data.staffPhoto.photoUrl) {
          photoUrl.value = res.data.staffPhoto.photoUrl;
        }
        title.value = formState.value.taskName;
        visible.value = true;
      });
    };

    const filterOption = (input, option) => {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };

    const imgUrl = ref("");
    const imgVisible = ref(false);
    const contentClick = (e) => {
      if (e.target.tagName.toLowerCase() === "img") {
        imgUrl.value = e.target.src;
        imgVisible.value = true;
      }
    };
    const closeImgModal = () => {
      imgVisible.value = false;
      imgUrl.value = "";
    };

    const setCommentList = (list) => {
      commentList.value = list;
    };

    const init = () => {
      getTaskUserOps();
    };

    init();

    onUnmounted(() => {
      if (messageTimer) {
        clearTimeout(messageTimer);
      }
    });

    return {
      labelCol: { span: 3 },
      wrapperCol: { span: 20 },
      taskUserOps,
      filterOption,
      visible,
      title,
      formRef,
      formState,
      formRules,
      saveClick,
      publishClick,
      closeModal,
      createTask,
      editTask,
      fileList,
      columns,
      tableData,
      answerTableData,
      removeFile,
      beforeUpload,
      downloadClick,
      delClick,
      imgUrl,
      imgVisible,
      contentClick,
      closeImgModal,
      commentList,
      chooseTaskId,
      photoUrl,
      setCommentList,
    };
  },
});
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  overflow: auto;

  :deep {
    img {
      width: 100%;
      cursor: pointer;
    }

    h1 {
      font-size: 2em;
      font-weight: bold;
    }

    h2 {
      font-size: 1.5em;
      font-weight: bold;
    }

    h3 {
      font-size: 1.17em;
      font-weight: bold;
    }

    h4 {
      font-weight: bold;
    }

    h5 {
      font-size: 0.83em;
      font-weight: bold;
    }

    h6 {
      font-size: 0.67em;
      font-weight: bold;
    }

    ol {
      display: block;
      list-style-type: decimal;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }

    ul {
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    width: 10px;
    height: 10px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }

  :deep(.rich-file) {
    color: blue;
    cursor: pointer;
  }
}

.file-table {
  margin-top: 10px;
}

:deep(.lm-task-modal) {
  .ant-drawer-header {
    padding: 20px;

    h1 {
      border: 0;
      margin: 0;
      padding: 0;
      font-size: 22px;
    }
  }

  .ant-drawer-body {
    padding: 0;
  }

  .modal-body {
    padding: 0 20px 20px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }

  .footerBtn {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: right;
    z-index: 1;

    .cancelBtn,
    .addEmpBtn {
      margin: 10px 15px;
    }

    .deactivate-btn {
      position: absolute;
      top: 10px;
      left: 0;
    }
  }
}

h1 {
  font-weight: bold;
  font-size: 20px;
  border-bottom: 2px solid #0070ad;
  margin: 15px 0;
  padding: 5px 0;
}
</style>
