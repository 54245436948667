<template>
  <div class="backBenchTask" v-auth="['system:benchTask:lm']" ref="backRef">
    <div class="backE" @click="trunBackBench">
      <LeftOutlined style="fontsize: 13px; cursor: pointer" />
      <span class="back">返回上一级</span>
    </div>
    <div>
      <span class="nameShow" v-if="isChooseBtn"
        >已筛选人员姓名：{{ getChooseName }}</span
      >
      <a-button class="chooseBtn" @click="chooseConsultant">
        <MenuOutlined />
        筛选项
      </a-button>
      <a-button class="cancelChooseBtn" v-if="isChooseBtn" @click="resumeTask">
        <CloseOutlined />
      </a-button>
    </div>
  </div>
  <div class="benchTask" :style="{ height: `calc(100% - ${heightGap}px)` }">
    <div class="taskPool" v-for="(item, id) in progressData" :key="id">
      <div class="taskTitle">
        <span class="skillText">{{ item.laneStatus }}</span>
        <div
          id="create-newCard"
          ref="cardRef"
          @click="lmCreateTask"
          v-if="item.laneStatus == 'Task'"
          v-auth="['system:benchTask:lm']"
        >
          <a-button type="link" v-auth="['system:lmBenchTaskDetail:addTask']">
            <PlusOutlined />
            新建任务
          </a-button>
        </div>
        <div
          id="done-more"
          ref="cardRef"
          @click="searchMoreTask"
          v-show="item.laneStatus == 'Done' && item.tasks.length > 4"
        >
          <a-button type="link">
            <UpSquareOutlined v-show="!hiddenDoneTask" />
            <DownSquareOutlined v-show="hiddenDoneTask" />
            {{ hiddenDoneTask ? "查看全部完成任务" : "收起" }}
          </a-button>
        </div>
      </div>
      <div
        :class="[
          'cardsList',
          item.laneStatus == 'Done' && hiddenDoneTask ? 'hidden-card-list' : '',
        ]"
        :ref="(el) => (cardListRef[id] = el)"
      >
        <div
          class="cardContent"
          v-for="(carditem, index) in item.tasks"
          :key="index"
          @contextmenu.prevent="
            (e) => {
              showMore(id, index, e);
            }
          "
        >
          <div
            :class="['setCardBorder', setCardBorder(carditem, item.laneStatus)]"
          >
            <a-card
              @click="editTask(carditem, item.laneStatus)"
              :bordered="false"
            >
              <div class="titleH">
                <div class="title" :title="carditem.taskName">
                  {{ carditem.taskName }}
                </div>
                <div class="titleTag">
                  <a-tag
                    v-show="
                      item.laneStatus == 'Doing' &&
                      carditem.taskStatus == '3' &&
                      carditem.taskStatus !== '4'
                    "
                    v-auth="['system:benchTask:lm']"
                    >待处理
                  </a-tag>
                  <a-tag
                    color="red"
                    v-show="
                      item.laneStatus == 'Doing' && carditem.taskStatus == '4'
                    "
                    >未通过
                  </a-tag>
                  <a-tag
                    color="orange"
                    v-show="
                      item.laneStatus == 'Doing' && carditem.isAnswered == '0'
                    "
                    >未回答
                  </a-tag>
                </div>
              </div>
              <div class="cInfo">
                <a-avatar
                  style="margin-right: 5px"
                  v-if="carditem.photoUrl"
                  :src="carditem.photoUrl"
                >
                </a-avatar>
                <a-avatar style="margin-right: 5px" v-else>
                  <template #icon>
                    <UserOutlined />
                  </template>
                </a-avatar>
                <span>{{ carditem.userName }}</span>
              </div>
              <div class="bottomCon">
                <div class="timeIcon">
                  <DashboardOutlined
                    style="font-size: 13px; margin-right: 5px"
                  />
                  {{ carditem.beginTime }}~{{ carditem.endTime }}
                </div>
                <div class="btn">
                  <a-button
                    class="newTask"
                    v-show="
                      item.laneStatus == 'Task' && carditem.taskStatus == '0'
                    "
                    v-auth="['system:benchTask:lm']"
                    @click.stop="announceEvent(carditem, id, index)"
                    style="background: #2db7f5; color: #fff"
                    >发布
                  </a-button>
                  <a-button
                    disabled
                    class="newTask"
                    v-show="
                      item.laneStatus == 'Task' && carditem.taskStatus == '1'
                    "
                    v-auth="['system:benchTask:lmAll']"
                    >已发布
                  </a-button>
                  <a-button
                    class="newTask"
                    v-show="
                      item.laneStatus == 'Doing' &&
                      carditem.taskStatus !== '4' &&
                      carditem.taskStatus == '3'
                    "
                    v-auth="['system:benchTask:lmPass']"
                    style="background: #87d068; color: #fff"
                    @click.stop="passTask(carditem, id, index)"
                    >通过
                  </a-button>
                  <a-button
                    class="newTask"
                    v-show="
                      item.laneStatus == 'Doing' &&
                      carditem.taskStatus !== '4' &&
                      carditem.taskStatus == '3'
                    "
                    v-auth="['system:benchTask:lmReject']"
                    style="margin-right: 5px; background: #f50; color: #fff"
                    @click.stop="rejectTask(carditem)"
                    >驳回
                  </a-button>
                </div>
              </div>
            </a-card>
          </div>
          <div
            v-check="handleClickOutSide"
            v-if="carditem.isShowMore && item.laneStatus !== 'Done'"
            class="rightBottomBtn"
            :style="{ left: nowX + 'px', top: nowY + 'px' }"
          >
            <a-button
              type="link"
              class="mb5"
              @click="editTask(carditem, item.laneStatus)"
              v-auth="['system:benchTask:lmEdit']"
              >编辑
            </a-button>
            <a-button type="link" class="mb5" @click="copyTask(carditem)" v-auth="['system:benchTask:lmCopy']"
              >复制
            </a-button>
            <a-button
              type="link"
              class="mb5"
              @click="delectTask(carditem, id, index)"
              v-auth="['system:benchTask:lmDelete']"
              >删除
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chooseOne" v-check="closeChooseList" v-if="isChoosing">
    <div class="chooseTitle">
      <span>筛选</span>
      <CloseOutlined
        style="float: right; margin-top: 10px"
        @click="closeChooseList"
      />
    </div>
    <a-divider style="height: 2px; margin-bottom: 3px" />
    <div v-for="(item, index) in chooseList" :key="index" class="mb5">
      <a-checkbox v-model:checked="item.checked" @change="change(item, index)">
        {{ item.fullName }}
      </a-checkbox>
    </div>
    <a-divider />
    <div class="chooseTitle">
      <a-button type="link" @click="getTasks" v-auth="['system:benchTask:lmConfirm']">确定</a-button>
    </div>
  </div>

  <lm-task-modal
    ref="lmTaskRef"
    :taskStatus="taskStatus"
    @refresh-data="refreshData"
  />
  <consultant-task-modal
    ref="consultantTaskRef"
    :taskStatus="taskStatus"
    @refresh-data="refreshData"
  />
</template>

<script>
import { defineComponent, onActivated, onMounted, ref,watch } from "vue";
import {
  LeftOutlined,
  PlusOutlined,
  DashboardOutlined,
  UserOutlined,
  MenuOutlined,
  CloseOutlined,
  UpSquareOutlined,
  DownSquareOutlined,
} from "@ant-design/icons-vue";
import "@/assets/benchTask/link/iconfont.css";
import lmTaskModal from "@/views/home/TaskManagement/BenchTask/component/lmTaskModal.vue";
import { message } from "ant-design-vue";
import { useRouter,useRoute } from "vue-router";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import ConsultantTaskModal from "@/views/home/TaskManagement/BenchTask/component/consultantTaskModal";
import { useStore } from "vuex";

export default defineComponent({
  name: "lmBenchTaskDetail",
  components: {
    ConsultantTaskModal,
    PlusOutlined,
    lmTaskModal,
    LeftOutlined,
    DashboardOutlined,
    UserOutlined,
    MenuOutlined,
    CloseOutlined,
    UpSquareOutlined,
    DownSquareOutlined,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { $api } = useGlobalPropertyHook();
    const store = useStore();
    const progressData = ref([]);
    const nowX = ref(0);
    const nowY = ref(0);
    const heightGap = ref(0);
    const backRef = ref(null);
    const isChoosing = ref(false);
    const isChooseBtn = ref(false);
    const chooseList = ref([]);
    let getChooseId = [];
    const getChooseName = ref();
    let getName = [];
    const hiddenDoneTask = ref(true);
    const doneList = ref([]);
    const cardListRef = ref([]);

    const change = (item) => {
      if (item.checked == true) {
        getChooseId.push(item.id);
        getName.push(item.fullName);
      } else if (getChooseId.includes(item.id)) {
        let id = getChooseId.indexOf(item.id);
        getChooseId.splice(id, 1);
        getName.splice(id, 1);
      }
    };
    const getTasks = () => {
      const params = { userIdList: getChooseId };
      console.log(params);
      if (getChooseId?.length) {
        $api.getChooseList(params).then((res) => {
          if (res.code == 200) {
            closeChooseList();
            isChooseBtn.value = true;
            getChooseId = [];
            progressData.value = res.data;
            getChooseName.value = getName.toString();
            getName = [];
          }
        });
      } else {
        init();
      }
    };
    const chooseConsultant = () => {
      $api
        .queryEmpByLm({ lmIdNumber: store.state.app.user.idNumber })
        .then((res) => {
          chooseList.value = res.data;
          isChoosing.value = true;
        });
    };

    const closeChooseList = () => {
      isChoosing.value = false;
      getChooseId = [];
    };
    //Task
    const resumeTask = () => {
      isChooseBtn.value = false;
      getChooseId = [];
      getName = [];
      getChooseName.value = [];
      init();
    };

    //气泡卡片
    const showMore = (cardid, cardcontentid, e) => {
      handleClickOutSide();
      nowX.value = e.pageX;
      nowY.value = e.pageY;
      progressData.value[cardid].tasks[cardcontentid].isShowMore = true;
    };
    //点击空白关闭气泡卡片
    const handleClickOutSide = () => {
      progressData.value.map((item) => {
        item.tasks.map((data) => {
          data.isShowMore = false;
        });
      });
    };

    const lmTaskRef = ref();
    const lmCreateTask = () => {
      taskStatus.value = "Task";
      handleClickOutSide();
      lmTaskRef.value.createTask();
    };
    const lmEditTask = (record) => {
      handleClickOutSide();
      lmTaskRef.value.editTask(record.id);
    };

    const consultantTaskRef = ref();
    const taskStatus = ref("");
    const consultantEditTask = (record) => {
      consultantTaskRef.value.editTask(record.id);
    };

    const editTask = (record, status) => {
      taskStatus.value = status;
      if (store.state.app.perms.indexOf("system:benchTask:lm") !== -1) {
        lmEditTask(record);
      } else {
        consultantEditTask(record);
      }
    };

    const refreshData = () => {
      init();
    };

    //删除卡片
    const delectTask = (item, cardid, cardcontentid) => {
      handleClickOutSide();
      $api.delectBenchTaskList({ taskId: item.id }).then((res) => {
        if (res.code == 200) {
          progressData.value[cardid].tasks.splice(cardcontentid, 1);
          init();
        }
      });
    };
    //返回主页
    const trunBackBench = () => {
      router.push("/benchTask");
    };
    //发布
    const announceEvent = (item) => {
      if (item.beginTime && item.endTime && item.userName) {
        $api.publishBenchTaskList({ taskId: item.id }).then((res) => {
          if (res.code == 200) {
            message.success("任务发布成功");
            init();
          }
        });
      } else {
        message.warning("请将任务信息填写完全(可能是时间或人员信息未填写完全)");
      }
    };
    //接受
    const receiveTask = (item) => {
      $api.receiveBenchTaskList({ taskId: item.id }).then((res) => {
        if (res.code == 200) {
          init();
        }
      });
    };
    //通过
    const passTask = (item) => {
      $api.passBenchTaskList({ taskId: item.id }).then((res) => {
        if (res.code == 200) {
          init();
        }
      });
    };
    //驳回
    const rejectTask = (item) => {
      $api.rejectBenchTaskList({ taskId: item.id }).then((res) => {
        if (res.code == 200) {
          init();
        }
      });
    };
    //复制卡片
    const copyTask = (item) => {
      handleClickOutSide();
      $api.copyBenchTaskList({ taskId: item.id }).then((res) => {
        if (res.code == 200) {
          init();
        }
      });
    };

    //获取卡片边框颜色
    const setCardBorder = (card, title) => {
      if (title !== "Doing") return;
      if (card.overdue == "overdue") {
        return "setCardBorderRed";
      } else if (card.overdue == "approaching") {
        return "setCardBorderYellow";
      }
      return "setCardBorder";
    };

    //获取卡片信息
    const init = () => {
      $api
        .getChooseList({taskId:route?.query?.id})
        .then((res) => {
          progressData.value = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    //更多
    const searchMoreTask = () => {
      hiddenDoneTask.value = !hiddenDoneTask.value;
      cardListRef.value[cardListRef.value.length - 1].scrollTop = 0;
    };

    onMounted(() => {
      heightGap.value = backRef.value.offsetHeight;
    });

    onActivated(() => {
      getTasks();
    });
    watch(
      () => route.query.id,
      () => {
        getTasks()
      }
    )

    return {
      nowX,
      nowY,
      lmTaskRef,
      lmCreateTask,
      lmEditTask,
      consultantTaskRef,
      taskStatus,
      consultantEditTask,
      editTask,
      refreshData,
      showMore,
      handleClickOutSide,
      progressData,
      delectTask,
      trunBackBench,
      announceEvent,
      copyTask,
      receiveTask,
      passTask,
      rejectTask,
      heightGap,
      backRef,
      setCardBorder,
      isChoosing,
      chooseConsultant,
      chooseList,
      change,
      closeChooseList,
      getTasks,
      isChooseBtn,
      resumeTask,
      getChooseName,
      hiddenDoneTask,
      searchMoreTask,
      doneList,
      cardListRef,
    };
  },
});
</script>

<style lang="less" scoped>
.chooseOne {
  position: fixed;
  top: 120px;
  right: 50px;
  background: #fff;
  min-width: 250px;
  padding: 0px 10px 5px;
  box-shadow: 0px 0px 1.04167vw 0.15625vw #e9ebed;

  .chooseTitle {
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  :deep(.ant-divider-horizontal) {
    margin: 0px;
  }

  .mb5 {
    margin-bottom: 5px;
    font-weight: 600;
  }
}

.backBenchTask {
  line-height: 40px;
  margin: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .nameShow {
    margin-right: 10px;
  }

  .cancelChooseBtn {
    margin-right: 20px;
    border-color: #fff;

    &:focus {
      background-color: rgb(219 219 219 / 80%) !important;
    }
  }

  .chooseBtn {
    border-color: #fff;
  }

  .back {
    color: #000;
    margin-left: 3px;
    font-size: 15px;
    cursor: pointer;
  }
}

.benchTask {
  display: flex;
  width: 100%;
  padding: 10px 100px 10px;
  justify-content: space-between;

  > div {
    width: 31%;
    background: rgba(3, 14, 44, 0.04) !important;

    &:hover {
      box-shadow: 0 2px 8px #00000017;
    }
  }

  .skillText {
    font-size: 26px;
    font-weight: 500;
  }

  #create-newCard {
    background: white;
    text-align: center;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 5px;
    cursor: pointer;
  }

  #done-more {
    position: absolute;
    right: 0;
    top: 0;

    .ant-btn {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.taskPool {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  height: calc(100%);
  flex-direction: column;
}

.cardsListHeight {
  width: calc(100%);
  overflow: hidden !important;
}

.cardsList {
  width: calc(100%);
  overflow: auto;
  flex: 1;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }

  .cardContent {
    width: calc(100%);
    padding: 4px;

    .setCardBorder {
      border: 2px solid #d9d9d9;
      border-radius: 8px;
    }

    .setCardBorderRed {
      border: 2px solid #ff0000;
      border-radius: 8px;
    }

    .setCardBorderYellow {
      border: 2px solid #ffce00;
      border-radius: 8px;
    }
  }

  .icon {
    line-height: 26px;
  }

  .ant-card {
    cursor: pointer;
  }
}

.titleH {
  display: flex;
  flex-direction: row;
  height: 25px;
  line-height: 25px;

  .title {
    font-size: 18px;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;

    .timeData {
      font-weight: 200;
    }
  }
}

.time {
  color: #8d8d8d;
  font-size: 2px;
  float: right;
}

:deep(.ant-card-head-title) {
  padding: 3px 0px;
}

.sumbitBtn {
  border-radius: 4px;
}

:deep(.ant-card-body) {
  padding: 18px;
}

:deep(.ant-card) {
  border-radius: 8px;
}

.newTask {
  float: right;
  height: 32px;
  border-radius: 4px;
}

.taskTitle {
  line-height: 23px;
  margin-bottom: 16px;
  position: relative;
}

.rightBottomBtn {
  z-index: 1000;
  position: fixed;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  left: 0;
  top: 0;
  background: #fff;
  padding: 5px 30px 5px 5px;
  display: flex;
  flex-direction: column;

  .mb5 {
    margin-bottom: 5px;
    color: #000;
  }
}

.iconLink {
  color: #8d8d8d;
  font-size: 18px;
  margin-left: 10px;
}

// :deep(.ant-card-bordered) {
//   border: 1px solid #d9d9d9;
// }

:deep(.ant-popover-inner-content) {
  padding: 3px 41px 3px 0px;
}

.popoverCard {
  left: 390px;
}

:deep(.ant-popover-arrow) {
  display: none;
}

.bottomCon {
  .btn {
    height: 26px;
    margin-top: -25px;
  }
}

:deep(.ant-btn) {
  &:hover {
    border-color: transparent;
  }
}

.cInfo {
  margin: 15px 0px 15px 0px;
}

.hidden-card-list {
  height: 632px;
  max-height: 632px;
  overflow: hidden;
}
</style>
